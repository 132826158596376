var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout-main", [
    _c("div", { staticClass: "page-login" }, [
      _c("h1", [_vm._v("Авторизация")]),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.login($event)
            }
          }
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _c(
              "label",
              { staticClass: "control-label", attrs: { for: "email" } },
              [_vm._v("E-mail")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form_data.email,
                  expression: "form_data.email"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", id: "email", name: "email" },
              domProps: { value: _vm.form_data.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form_data, "email", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("email")
              ? _c("span", { staticClass: "validation-error" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.errors.first("email")) +
                      "\n                "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c(
              "label",
              { staticClass: "control-label", attrs: { for: "password" } },
              [_vm._v("Пароль")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form_data.password,
                  expression: "form_data.password"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "password", id: "password", name: "password" },
              domProps: { value: _vm.form_data.password },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form_data, "password", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.has("password")
              ? _c("span", { staticClass: "validation-error" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.errors.first("password")) +
                      "\n                "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c(
              "button",
              { staticClass: "btn btn-primary", on: { click: _vm.login } },
              [_vm._v("Войти")]
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }